
import { defineComponent } from 'vue';
import fiabilidadSiVacio from '@/components/fiabilidadSiVacio.vue'
import { reglas } from '@/fuentes'
import { estadoGrupoDeEntes, listarGruposDeEntesUnaVez } from '@/gruposDeEntes'

export default defineComponent({
  name: 'Reglas',
  data: () => ({
		reglas: reglas,
		estadoGrupoDeEntes: estadoGrupoDeEntes,
		grupoDeEntes: '',
		reglasDeVariables: {
			origen: {
				fiabilidad: {
					siEstaVacia: 0.95,
				},
				direccionIP: {
					fiabilidad: {
						siEstaVacia: 0.95,
					},
				},
				coordenadas: {
					fiabilidad: {
						siEstaVacia: 0.95,
					},
				},
			},
			dispositivo: {
				fiabilidad: {
					siEstaVacia: 0.8,
				},
				identificador: {
					fiabilidad: {
						siEstaVacia: 0.8,
					},
				},
				plataforma: {
					fiabilidad: {
						siEstaVacia: 0.9,
					},
				},
				navegador: {
					window: {
						fiabilidad: {
							siEstaVacia: 0.9,
						},
					},
				}
			},
			adquisicion: {
				fiabilidad: {
					siEstaVacia: 1,
				},
				pago: {
					fiabilidad: {
						siEstaVacia: 0.9,
					},
					tarjeta: {
						fiabilidad: {
							siEstaVacia: 0.9,
						},
					},
					precio: {
						fiabilidad: {
							siEstaVacia: 0.9,
						},
					},
				},
				receptorDeFacturacion: {
					fiabilidad: {
						siEstaVacia: 1,
					}
				},
				destinatarioDeEnvio: {
					fiabilidad: {
						siEstaVacia: 1,
					}
				},
			},
		}
	}),
	components: {
		fiabilidadSiVacio
	},
	mounted() {
		listarGruposDeEntesUnaVez().then(() => {
			if (this.estadoGrupoDeEntes.listado.length === 1) {
				this.grupoDeEntes = this.estadoGrupoDeEntes.listado[0].identificador
			}
		})
	},
	watch: {
		'grupoDeEntes': function() {
			if (!this.grupoDeEntes) {
				this.limpiar()
				return
			}

			for (let g of this.estadoGrupoDeEntes.listado) {
				if (g.identificador === this.grupoDeEntes) {
					this.reglasDeVariables = JSON.parse(JSON.stringify(g.reglas.variables))
					break
				}
			}
		}
	},
	methods: {
		guardar: function() {
			if (this.reglas.estado.guardando) {
				return
			}

			this.reglas.guardar({
				grupoDeEntes: this.grupoDeEntes,
				variables: JSON.parse(JSON.stringify(this.reglasDeVariables))
			})
		},
		limpiar: function() {
			this.reglasDeVariables = {
				origen: {
					fiabilidad: {
						siEstaVacia: 0,
					},
					direccionIP: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
					coordenadas: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
				},
				dispositivo: {
					fiabilidad: {
						siEstaVacia: 0,
					},
					identificador: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
					plataforma: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
					navegador: {
						window: {
							fiabilidad: {
								siEstaVacia: 0,
							},
						},
					}
				},
				adquisicion: {
					fiabilidad: {
						siEstaVacia: 0,
					},
					pago: {
						fiabilidad: {
							siEstaVacia: 0,
						},
						tarjeta: {
							fiabilidad: {
								siEstaVacia: 0,
							},
						},
						precio: {
							fiabilidad: {
								siEstaVacia: 0,
							},
						},
					},
					receptorDeFacturacion: {
						fiabilidad: {
							siEstaVacia: 0,
						}
					},
					destinatarioDeEnvio: {
						fiabilidad: {
							siEstaVacia: 0,
						}
					},
				},
			}
		}
	}
});
