
<template>

	<div class="contenedor" style="padding: 10px 16px; margin-bottom: 40px">
		<h3>Reglas</h3>

		<hr>

		<div class="uk-margin">
			<label class="uk-form-label" for="grupo-entes-entes">Grupo de entes:</label>
			<div class="uk-form-controls">
				<select class="uk-select" id="grupo-entes-entes" v-model="grupoDeEntes">
					<option v-for="g in estadoGrupoDeEntes.listado" :value="g.identificador" :key="`rgls-ge-${g.identificador}`">
						{{ g.descripcion }} ({{ g.identificador }})
					</option>
				</select>
			</div>
		</div>
	</div>

	<!-- ---------- agregar sección de autoapendizaje de correo y teléfono... -------- POR AQUÍ VOY ----------  -->

	<form @submit.prevent="guardar">

			<h4>Cálculos de fiabilidad de variables de origen</h4>

			<div class="contenedor" style="padding: 10px 16px">

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.origen.fiabilidad.siEstaVacia"
					variable="origen"
					id="orgn"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.origen.direccionIP.fiabilidad.siEstaVacia"
					variable="origen.direccionIP"
					id="orgn-drcnip"
					espaciado="20"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.origen.coordenadas.fiabilidad.siEstaVacia"
					variable="origen.coordenadas"
					id="orgn-crdnds"
					espaciado="20"
				></fiabilidad-si-vacio>

			</div>

			<h4>Cálculos de fiabilidad de variables de dispositivo</h4>

			<div class="contenedor" style="padding: 10px 16px">

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.dispositivo.fiabilidad.siEstaVacia"
					variable="dispositivo"
					id="dpstv"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.dispositivo.identificador.fiabilidad.siEstaVacia"
					variable="dispositivo.identificador"
					id="dpstv-idtfcdr"
					espaciado="20"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.dispositivo.plataforma.fiabilidad.siEstaVacia"
					variable="dispositivo.plataforma"
					id="dpstv-ptfrma"
					espaciado="20"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.dispositivo.navegador.window.fiabilidad.siEstaVacia"
					variable="dispositivo.navegador.window"
					id="dpstv-nvgdr-wdw"
					espaciado="20"
				></fiabilidad-si-vacio>

			</div>

			<h4>Cálculos de fiabilidad de variables de adquisición</h4>

			<div class="contenedor" style="padding: 10px 16px">

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.adquisicion.fiabilidad.siEstaVacia"
					variable="adquisicion"
					id="adqscn"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.adquisicion.pago.fiabilidad.siEstaVacia"
					variable="adquisicion.pago"
					id="adqscn-pgo"
					espaciado="20"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.adquisicion.pago.tarjeta.fiabilidad.siEstaVacia"
					variable="adquisicion.pago.tarjeta"
					id="adqscn-pgo-tjta"
					espaciado="40"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.adquisicion.pago.precio.fiabilidad.siEstaVacia"
					variable="adquisicion.pago.precio"
					id="adqscn-pgo-pcio"
					espaciado="40"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.adquisicion.receptorDeFacturacion.fiabilidad.siEstaVacia"
					variable="adquisicion.receptorDeFacturacion"
					id="adqscn-rdf"
					espaciado="20"
				></fiabilidad-si-vacio>

				<fiabilidad-si-vacio
					v-model="reglasDeVariables.adquisicion.destinatarioDeEnvio.fiabilidad.siEstaVacia"
					variable="adquisicion.destinatarioDeEnvio"
					id="adqscn-dde"
					espaciado="20"
				></fiabilidad-si-vacio>

			</div>

			<br>
			<br>

			<button class="uk-button uk-button-primary" type="submit" :disabled="reglas.estado.guardando">Guardar</button>

	</form>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import fiabilidadSiVacio from '@/components/fiabilidadSiVacio.vue'
import { reglas } from '@/fuentes'
import { estadoGrupoDeEntes, listarGruposDeEntesUnaVez } from '@/gruposDeEntes'

export default defineComponent({
  name: 'Reglas',
  data: () => ({
		reglas: reglas,
		estadoGrupoDeEntes: estadoGrupoDeEntes,
		grupoDeEntes: '',
		reglasDeVariables: {
			origen: {
				fiabilidad: {
					siEstaVacia: 0.95,
				},
				direccionIP: {
					fiabilidad: {
						siEstaVacia: 0.95,
					},
				},
				coordenadas: {
					fiabilidad: {
						siEstaVacia: 0.95,
					},
				},
			},
			dispositivo: {
				fiabilidad: {
					siEstaVacia: 0.8,
				},
				identificador: {
					fiabilidad: {
						siEstaVacia: 0.8,
					},
				},
				plataforma: {
					fiabilidad: {
						siEstaVacia: 0.9,
					},
				},
				navegador: {
					window: {
						fiabilidad: {
							siEstaVacia: 0.9,
						},
					},
				}
			},
			adquisicion: {
				fiabilidad: {
					siEstaVacia: 1,
				},
				pago: {
					fiabilidad: {
						siEstaVacia: 0.9,
					},
					tarjeta: {
						fiabilidad: {
							siEstaVacia: 0.9,
						},
					},
					precio: {
						fiabilidad: {
							siEstaVacia: 0.9,
						},
					},
				},
				receptorDeFacturacion: {
					fiabilidad: {
						siEstaVacia: 1,
					}
				},
				destinatarioDeEnvio: {
					fiabilidad: {
						siEstaVacia: 1,
					}
				},
			},
		}
	}),
	components: {
		fiabilidadSiVacio
	},
	mounted() {
		listarGruposDeEntesUnaVez().then(() => {
			if (this.estadoGrupoDeEntes.listado.length === 1) {
				this.grupoDeEntes = this.estadoGrupoDeEntes.listado[0].identificador
			}
		})
	},
	watch: {
		'grupoDeEntes': function() {
			if (!this.grupoDeEntes) {
				this.limpiar()
				return
			}

			for (let g of this.estadoGrupoDeEntes.listado) {
				if (g.identificador === this.grupoDeEntes) {
					this.reglasDeVariables = JSON.parse(JSON.stringify(g.reglas.variables))
					break
				}
			}
		}
	},
	methods: {
		guardar: function() {
			if (this.reglas.estado.guardando) {
				return
			}

			this.reglas.guardar({
				grupoDeEntes: this.grupoDeEntes,
				variables: JSON.parse(JSON.stringify(this.reglasDeVariables))
			})
		},
		limpiar: function() {
			this.reglasDeVariables = {
				origen: {
					fiabilidad: {
						siEstaVacia: 0,
					},
					direccionIP: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
					coordenadas: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
				},
				dispositivo: {
					fiabilidad: {
						siEstaVacia: 0,
					},
					identificador: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
					plataforma: {
						fiabilidad: {
							siEstaVacia: 0,
						},
					},
					navegador: {
						window: {
							fiabilidad: {
								siEstaVacia: 0,
							},
						},
					}
				},
				adquisicion: {
					fiabilidad: {
						siEstaVacia: 0,
					},
					pago: {
						fiabilidad: {
							siEstaVacia: 0,
						},
						tarjeta: {
							fiabilidad: {
								siEstaVacia: 0,
							},
						},
						precio: {
							fiabilidad: {
								siEstaVacia: 0,
							},
						},
					},
					receptorDeFacturacion: {
						fiabilidad: {
							siEstaVacia: 0,
						}
					},
					destinatarioDeEnvio: {
						fiabilidad: {
							siEstaVacia: 0,
						}
					},
				},
			}
		}
	}
});
</script>

<style scoped>

h4 {
	margin-bottom: 10px;
}

</style>